#header {
    height: @headerH;
    display: flex;
    align-items: center;
    padding: 0 @margin;

    .logo {
        flex-shrink: 0;
        background: url('assets/logo.svg') no-repeat center;
        background-size: contain;
        width: @headerH + @margin;
        height: @headerH - @margin;
        margin-right: @margin;
    }

    h1.title {
        font-family: 'Reglo', sans-serif;
        color: black;
        font-size: 1.5rem;
    }

    .lang-switch {
        margin-left: auto;
    }
}