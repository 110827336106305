* {
    .scroll-mixin();
    box-sizing: border-box;

}

body {
    font-family: 'SpaceMono', sans-serif;
    margin: 0px;
    color: @blue;
}


a {
    color: inherit;
    font-family: 'Reglo', sans-serif;

}



#map {
    position: absolute;
    left: 15px;
    top: @headerH;
    bottom: @footerH;
    width: calc(~"70% - 15px");
    border: @border
}



h1,
h2,
h3 {
    font-family: 'SpaceGrotesk', sans-serif;
    text-transform: uppercase;
    color: @blue;
    font-weight: 100;
}


h1 {
    font-size: 2rem;
    margin: 0px;
}


h2 {
    font-size: 1.65rem;
}


h3 {
    font-size: 1.2rem;
}

.disclaimer {
    margin-top: @margin * 2;
    font-size: 1rem;
    // font-style: italic;
    max-width: 35em;
}


.the-sidebar {
    position: absolute;
    right: 0;
    top: @headerH;
    bottom: @footerH;
    width: 30%;
    padding: 0 @margin;
    overflow-y: auto;
    overflow-x: hidden;
    // display: flex;
    // flex-direction: column;
    .scroll-mixin();

    #info {
        // .scroll-mixin();
        // overflow-y: auto;
        padding-right: @margin;
        padding-bottom: @margin;
        // border-bottom: @border;
        color: black;

        * {
            color: inherit;
        }

        h3,
        .intro {
            margin-top: @margin;
        }

        .table {
            margin-top: 20px;
        }

    }

    .legend__wrapper {
        flex-shrink: 0;
    }
}


.kv {
    display: flex;
    border-top: @border;

    &:last-child {
        border-bottom: @border;
    }

    >div {
        padding: 3px;
        flex: 1;
    }

    .key {
        font-family: 'Reglo', sans-serif;
    }

    &.Remarque {
        color: grey;
        font-style: italic;

        div {
            border: 0;
        }
    }

    &.s_autre {
        display: initial;


        div {
            display: inline-block;
            border: 0;
            flex-shrink: 1;
            font-weight: initial;

            &.key:after {
                content: ' : ';
            }
        }

    }
}

.graph-wrapper {
    .graph {
        display: flex;

        .barchart-bar {
            height: 1.5rem;
            padding: 20px 0px;
            text-align: center;
            // &:nth-child(odd) .label {
            //     margin-top:-20px
            // }
            // &:nth-child(even) .label {
            //     margin-top: 20px;
            // }
        }

        .title {
            font-size: 1.5rem;
        }
    }

    .graph-labels {
        display: flex;

        .barchart-label {
            flex-shrink: 0;
            min-width: 1.3em;
            text-align: center;
        }
    }

    .graph-legend-wrapper {
        padding: 10px 0px;

        .graph-legend {
            display: flex;
            align-items: center;
            margin-bottom: @small-margin;

            .color {
                flex-shrink: 0;
                display: block;
                margin-right: @margin;
                width: 1rem;
                height: 1rem;
                border-radius: 1rem;
                // stroke:1px;
            }
        }
    }
}


.ol-zoom {
    top: initial;
    left: initial;
    bottom: @margin;
    right: @margin;

    .ol-zoom-in,
    .ol-zoom-out {
        border-radius: 100%;
    }

    button {
        display: block;
        margin: 1px;
        color: black;
        background-color: white;
        border: 2px solid black;

        &:hover {
            border-color: @activeColor;
            color: @activeColor;
            background-color: white;
        }
    }

    .ol-zoom-in {
        margin-bottom: (@margin / 2);
    }
}

.ol-control {
    background-color: initial;
    padding: 0;

    &:hover {
        background-color: initial;
    }
}