@font-face {
    font-family: 'SpaceGrotesk';
    src: url('assets/font/SpaceGrotesk-Regular.woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Reglo';
    src: url('assets/font/reglo-bold.woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'SpaceMono';
    src: url('assets/font/SpaceMono-Regular.woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SpaceMono';
    src: url('assets/font/SpaceMono-Bold.woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'SpaceMono';
    src: url('assets/font/SpaceMono-Italic.woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'SpaceMono';
    src: url('assets/font/SpaceMono-BoldItalic.woff');
    font-weight: bold;
    font-style: italic;
}