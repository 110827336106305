form {
    display: flex;
    flex-direction: column;
    margin:20px;
    label {
        padding-top: 15px;
    
    }
    input, select, textarea {
        max-width: 50%;
    }
    input[type="submit"] {
        margin-top: 20px;
        width: 5rem;
    }
}