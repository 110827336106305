.legend__wrapper {
    width: 100%;

    .legendContent {
        display: flex;
        // margin-top: @margin;
        border-top: @border;
        padding-top: @margin;
        margin-bottom: @margin;
        border-bottom: @border;
        padding-bottom: @margin;

        >div {
            flex: 1;

            &.color {
                // border-right: @border;
                padding-right: (@margin / 2);
            }

            &.size {
                padding-left: (@margin / 2);
            }
        }
    }
}


.legend-element {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: @small-margin;

    &:hover {
        text-decoration: underline;

        &.unknown {
            text-decoration: none;
            cursor: auto;
        }
    }

    &.selected {
        text-decoration: underline;
        font-weight: bold;
    }

    .color {
        flex-shrink: 0;
        display: block;
        margin-right: @margin;
        width: 1.2rem;
        height: 1.2rem;
        border-radius: 1.2rem;
        stroke: 1px;
    }

    .size-wrapper {
        flex-shrink: 0;
        width: 32px;
        margin-right: @margin;
        display: flex;
        align-items: center;
        justify-content: center;

        .size {
            background-color: gray;
            flex-shrink: 0;
            display: block;
            border-radius: 100%;
            margin: auto;
        }
    }

    &.unknown {
        .size {
            position: relative;

            &:before {
                content: ' ';
                width: 8px;
                height: 8px;
                border-radius: 10px;
                background-color: white;
                position: absolute;
                top: 2px;
                right: 2px;
            }
        }
    }
}

.connection-info {
    margin-top: @margin*2;
}

.pie-chart {
    width: 70%;
    min-width: 250px;
    margin: @margin*2 auto;
}

li.feature-item {
    cursor: default;

    &:hover {
        text-decoration: underline;
    }
}