#footer {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: @footerH;
    padding: @small-margin @margin ;
    font-size: 0.75rem;
    color: @greyDark;

    p {
        margin: 0px;
    }

    a {
        color: inherit;
    }
}