.root {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    // justify-content: center;
    .logo2 {
        background: url('assets/logo.svg') no-repeat center;
        background-size: contain;
        width: 300px;
        height: 300px;
    }

    * {
        text-align: center;
    }

    h2 a {
        // text-transform: none;
    }
}