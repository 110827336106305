fieldset.switch__fieldset {
    position: absolute;
    top: @margin;
    right: @margin;
    padding: (@margin / 2);
    border: 0px;
    background-color: white;
}

.switch__body {

    label {
        display: flex;
    }

    input {
        margin-right: @small-margin;
    }

    .switch__item {
        &:hover {
            .legend-option {
                text-decoration: underline;
            }
        }
    }

}